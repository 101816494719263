@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.control-panel {
  position: fixed !important; /* Fixed position */
  top: 0; /* Top left corner */
  left: 0;
  z-index: 1000; /* Higher z-index to float above other elements */
}

.key-legend {
  background: #f8f6ee; /* Optional: Background color */
  padding: 6px; /* Optional: Some padding */
  border: 2px solid #000000; /* Optional: A light border */
  font-weight: 800;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
}

.strain-select-info {
  font-size: 11px;
  margin-left: 2px;
  margin-top: 6px;
  font-weight: 500;
  max-width: 160px;
}

.strain-info-container {
  max-width: 140px;
  font-size: 12px;
  text-align: center;
  background: #a4ac86; /* Optional: Background color */
  padding: 7px; /* Optional: Some padding */
  border: 1px solid #a4ac86; /* Optional: A light border */
  font-weight: 500;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 6px;
}

.strain-info-container-alebrije {
  max-width: 100px;
  font-size: 16px;
  text-align: center;
  background: #f3f5e6; /* Optional: Background color */
  padding: 6px; /* Optional: Some padding */
  border: 1px solid #f3f5e6; /* Optional: A light border */
  font-weight: 500;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
}

.zoom-container {
  max-width: 75px;
  text-align: center;
  background: #a4ac86; /* Optional: Background color */
  padding: 6px; /* Optional: Some padding */
  border: 1px solid #a4ac86; /* Optional: A light border */
  font-weight: 500;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
}

.zoom-container-alebrije {
  max-width: 75px;
  text-align: center;
  background: #f3f5e6; /* Optional: Background color */
  padding: 6px; /* Optional: Some padding */
  border: 1px solid #e6e4e4; /* Optional: A light border */
  font-weight: 800;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.strain-dropdown {
  font-size: 14px; /* Adjust as needed for desktop view */
  /* other styles */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  /* Adjust max-width as needed */
  .strain-dropdown {
    font-size: 20px; /* Larger font size for mobile */
    /* additional responsive styles if needed */
  }
}
